<template>
  <div id="forgetPassPage">
    <div id="forgetPassBox">
      <!-- Logo -->
      <div class="logoContainer">
        <img class="logoIcon" src="https://app.growlytics.in/img/logo-full.385a21b0.png" />
        <div class="headerText">Create account to accept invite.</div>
      </div>

      <div v-if="fetchingInvite" class="inviteLoader" v-loading="true"></div>

      <template v-else>
        <template v-if="invite.success">
          <!-- Register Form -->
          <el-form v-if="invite.type == 'register'" ref="registerForm" class="registerForm" :model="registerForm" :rules="registerFormRules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="First Name" prop="first_name" style="padding-bottom: 10px">
                  <el-input type="text" v-model="registerForm.first_name" required></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Last Name" prop="last_name" style="padding-bottom: 10px">
                  <el-input v-model="registerForm.last_name" required></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="Email Id" prop="email" style="padding-bottom: 10px">
              <el-input :disabled="true" type="email" v-model="registerForm.email" required></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password" style="padding-bottom: 10px">
              <el-input id="passwordInput" type="password" v-model="registerForm.password" required></el-input>
            </el-form-item>
            <div class="buttonContainer">
              <el-button type="success" class="el-button el-button--primary submitBtn" :loading="registerProcessing" @click="onRegisterSubmit"> Accept Invite </el-button>
            </div>

            <div class="legalInfo" style="font-size: 10px; text-align: center; margin-top: 20px; line-height: 13px">
              By clicking accept invite button, you agree to our
              <a href="http://growlytics.in/terms-of-use/" target="_blank">Terms of Service</a>
              and have read and acknowledge our
              <a href="http://growlytics.in/privacy-policy/" target="_blank">Privacy Policy</a>.
            </div>
          </el-form>

          <!-- Login Form -->
          <el-form v-else ref="loginForm" class="loginForm" :model="loginForm" :rules="loginFormRules">
            <div class="headerText">Login and accept invite.</div>
            <!-- Email Id -->
            <el-form-item label="Email Id" prop="email">
              <el-input :disabled="true" type="email" v-model="loginForm.email" required></el-input>
            </el-form-item>

            <el-form-item label="Password" prop="password">
              <el-input id="passwordInput" type="password" v-model="loginForm.password" required></el-input>
            </el-form-item>

            <div class="buttonContainer">
              <el-button type="success" :loading="loginProcessing" @click="onLoginSubmit"> Accept Invite </el-button>
            </div>
          </el-form>
        </template>

        <!--  Invalid request -->
        <template v-else>
          <div class="invalidRequest">
            Hey, looks like the invite link is expired or cancelled by account admin. <br /><br /><br />
            <el-button type="success" class="submitBtn" @click="$router.push({ name: 'Register' })"> Sign Up </el-button>
            &nbsp; or
            <el-button type="success" @click="$router.push({ name: 'Login' })">Login In</el-button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import invitationComponent from './invitationComponent';
export default invitationComponent;
</script>

<style lang="scss" src="./invitation.scss"></style>
