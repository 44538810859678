import authService from '../../../services/auth';

export default {
  name: 'forgotPassword',
  data() {
    return {
      fetchingInvite: true,
      invite: null,

      errorMsg: '',

      // Login Form
      loginProcessing: false,
      loginForm: null,
      loginFormRules: null,

      // Signup Form
      registerProcessing: false,
      registerForm: null,
      registerFormRules: null,
      validationRules: null
    };
  },
  methods: {
    //#region  --------- Start: Login ----------------

    async onLoginSubmit() {
      try {
        this.errorMsg = null;

        // Validate Form
        let isValidData = await this.validateForm(this.$refs.loginForm);
        if (!isValidData) return;

        this.loginProcessing = true;

        let params = {
          token: this.invite.token,
          password: this.loginForm.password
        };

        let result = await authService.acceptInvitation(params);
        if (result.data.success) {
          // Genetate session and continue to dashboard.
          this.handleNewSession(result.data);
        } else {
          this.errorMsg = result.data.message;
        }

        this.loginProcessing = false;
      } catch (err) {
        console.error(err);
        //Something is wrong, please contact support.
        this.errorMsg = `Something's wrong. Please contact our support team.`;
        this.loginProcessing = false;
      }
    },

    //#endregion  --------- End: Login ----------------

    //#region  --------- Start: Signup ----------------

    async onRegisterSubmit() {
      try {
        this.errorMsg = null;

        // Validate Form
        let isValidData = await this.validateForm(this.$refs.registerForm);
        if (!isValidData) return;

        this.registerProcessing = true;

        let params = {
          token: this.invite.token,
          firstName: this.registerForm.first_name,
          lastName: this.registerForm.last_name,
          password: this.registerForm.password
        };

        let result = await authService.acceptInvitation(params);
        if (result.data.success) {
          // Genetate session and continue to dashboard.
          this.handleNewSession(result.data);
        } else {
          this.errorMsg = result.data.message;
        }

        this.registerProcessing = false;
      } catch (err) {
        console.error(err);
        //Something is wrong, please contact support.
        this.errorMsg = `Something's wrong. Please contact our support team.`;
        this.registerProcessing = false;
      }
    },

    //#endregion  --------- Start: Signup ----------------

    async fetchInvitationDetails(token) {
      try {
        let result = await authService.verifyInviteToken({ token: token });

        this.invite = result.data;
        this.invite.token = token;
        if (this.invite.success) {
          this.loginForm.email = this.invite.email;
          this.registerForm.email = this.invite.email;
        }

        this.fetchingInvite = false;
      } catch (err) {
        this.reportError(err);
        console.error(err);
        this.errorToast(`Something's not right. Please contact our support team.`);
        this.fetchingInvite = false;
      }
    }
  },

  created() {
    // Read form and rules from mixin
    this.registerForm = JSON.parse(JSON.stringify(this.getRegisterationForm()));
    this.registerFormRules = JSON.parse(JSON.stringify(this.getRegisterationFormRules()));
    this.loginForm = JSON.parse(JSON.stringify(this.getLoginForm()));
    this.loginFormRules = JSON.parse(JSON.stringify(this.getLoginFormRules()));

    // Check if token is available.
    if (this.$route.query && this.$route.query.token) {
      this.fetchInvitationDetails(this.$route.query.token);
    } else {
      this.$router.replace('/login');
    }
  }
};
